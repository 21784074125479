import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";

import { FormWrapper, FormTitle } from "./styles";

const ApplicationForm = (props) => {
	const { title, portalId, formId, fontColor, parentSection, redirectionTo } =
		props;

	const formWrapperRef = useRef(null);
	const [hsformsLoaded, setHsformsLoaded] = useState(false);

	let randomFormId = Math.floor(Math.random() * 1000 + 1);

	useEffect(() => {
		if (typeof window !== "undefined") {
			if (window.hbspt) {
				const formDiv = document.createElement("div");
				formDiv.id = `hubspotForm-${formId}-${randomFormId}`;
				formDiv.className = "formContainer";
				formWrapperRef.current.appendChild(formDiv);
				window.hbspt.forms.create({
					portalId: portalId,
					formId: formId,
					target: `#hubspotForm-${formId}-${randomFormId}`,
				});
			} else {
				const invervalID = setInterval(() => {
					if (window.hbspt) {
						clearInterval(invervalID);
						setHsformsLoaded(true);
					}
				}, 100);
			}

			if (redirectionTo.enable && redirectionTo.link) {
				window.addEventListener("message", redirectTo);
			}
		}

		return () => {
			if (redirectionTo.enable && redirectionTo.link) {
				window.removeEventListener("message", redirectTo);
			}
		};
	}, [hsformsLoaded]);

	const redirectTo = (event) => {
		if (
			formId === event.data.id &&
			event.data.type === "hsFormCallback" &&
			event.data.eventName === "onFormSubmitted"
		) {
			if (redirectionTo.target === "_self") {
				window.location.assign(
					`${redirectionTo.link}${
						window.contacts
							? `?${new URLSearchParams(window.contacts).toString()}`
							: ""
					}`,
				);
			} else {
				window.open(
					`${redirectionTo.link}${
						window.contacts
							? `?${new URLSearchParams(window.contacts).toString()}`
							: ""
					}`,
				);
			}
		}
	};

	return (
		<FormWrapper
			className="application-form"
			ref={formWrapperRef}
			parentSection={parentSection}
		>
			<FormTitle parentSection={parentSection} fontColor={fontColor}>
				{title}
			</FormTitle>
		</FormWrapper>
	);
};

ApplicationForm.propTypes = {
	title: PropTypes.string,
	portalId: PropTypes.string,
	formId: PropTypes.string,
	fontColor: PropTypes.string,
	parentSection: PropTypes.string,
};

export default ApplicationForm;
