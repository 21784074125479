import styled from "styled-components";

export const FormWrapper = styled.div`
	display: block;
	p {
		text-align: center;
		font-size: 18px;
		line-height: 23px;
	}
	&.application-form iframe {
		width: 100% !important; // hubspot adds inline style to iframe. Which has higher Specificity. My last option to use !important to overide inline style to make form fields responsive.
	}
	.hsform-loaded {
		padding: 0 1px !important;
	}
`;

export const FormTitle = styled.p`
	font-size: 22px;
	font-weight: 600;
	text-align: center;
	margin: ${(props) =>
		props.parentSection === "left" ? "0px 0px 10px 0px" : "0px 0px 30px 0px"};
	color: ${(props) => props.fontColor};
`;
